<template>
  <div class="box">
    <div class="list-item">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div>
                <div>
                  <img :src="formatPic(sfcList.goodsPic)" class="goods-img">
                </div>
                <div style="font-size: 16px; font-weight: 600;padding: 3px 10px;">
                  {{ sfcList.goodsName }}
                </div>
                <div style="padding: 3px 10px;margin-top:1px;display:flex;justify-content: space-between;align-items: center;">
                  <div>
                    <span style="margin-right: 5px"
                      ><van-tag type="primary"
                        >数量：{{ sfcList.goodsNum }}{{ sfcList.goodsUnit }}</van-tag
                      ></span
                    >
                    <!-- <span style="margin-right: 5px"
                      ><van-tag type="success">{{ sfcList.goodsPrice }}元</van-tag></span
                    > -->
                  </div>
                  <div
                      style="font-size: 22px; font-weight: 500;color: #eca530;"
                    >¥{{ sfcList.goodsPrice
                      }}<span style="font-size: 12px; margin-left: 2px"></span>
                    </div>
                </div>
                <div style="padding: 3px 10px;margin-top:3px;display:flex;align-items: center;">
                  <img :src="sfcList.users.headimgurl"  style="width:20px;height:20px;border-radius:50%;">
                  <span style="font-size:14px;margin-left:6px;">{{ sfcList.users.nickname }}</span>
                </div>
              </div>
            </div>
            <van-divider :style="{ color: '#c1c1c1', borderColor: '#c1c1c1',  }">详情</van-divider>
            <div style="display: flex;flex-direction: column;">
              <img v-for="(item, index) in formatPicList(sfcList.goodsPic)" :src="item" :key="index" class="goods-img1">
            </div>
            <div style="height:50px;" v-if="sfcList.users.id!=myInfo.id"></div>
            <div class="btm-box" v-if="sfcList.users.id!=myInfo.id">
              <div class="cklx" style="margin-right:10px;background: #2d83e8;color: #fff;" @click="fengxiang()">我想要</div>
            </div>
    </div>
    <loading :show="isShowLoading"></loading>
    <van-popup v-model="showTishi" position="top">
            <div style="width: 100%; text-align: right">
                <img
                    style="width: 55%"
                    src="./comm/tishi.png"
                    alt=""
                    @click="closeTiShi"
                />
            </div>
        </van-popup>
  </div>
</template>
<script>
// https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf1e471795a1fd4e4&redirect_uri=http://sh.yayale.top/login?resouce=goodslist&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect


import { Popup,Dialog, Divider, Toast } from "vant";
import dayjs from 'dayjs'
import AMap from "AMap"; // 引入高德地图
// import Empty from "./comm/empty.vue";
import Loading from "./comm/loading.vue";
import { passenger } from "@/api";
import { goods } from "@/api";
const wx = window.wx;
export default {
  components: {
    // Empty: Empty,
    Loading: Loading,
    VanDivider: Divider,
    // VanTreeSelect: TreeSelect,
    VanPopup: Popup
  },
  data() {
    return {
      activeId: "",
      active: 0,
      opacitys: 0,
      opacitys1: 0,
      showTishi: false,
      items: [
        { text: "食品"},
        { text: "百货"},
        { text: "生活用品"},
        { text: "电器"},
        { text: "建筑用品"},
        { text: "服饰"},
        { text: "其他"}
      ],
      isShowLoading: true,
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: "全部区域", value: 0 },
        { text: "西湖区", value: 1 },
        { text: "滨江区", value: 2 },
      ],
      sfcList: [],
      myInfoType: null,
      myInfo: null,
      toDay: "",
      weatherInfo: null,
      myTx: null
    };
  },

  mounted() {
    document.title = "闲置物品信息共享平台";
    this.toDay = dayjs().format('YYYY年MM月DD日')
        this.myInfoType = JSON.parse(localStorage.getItem("user")).types
        this.myInfo = JSON.parse(localStorage.getItem("user"))
        this.getwxSign()
        // this.getPassengerTripDistrictList()
        this.getPassengerTripList();
    //加载天气查询插件
    let _this = this
    AMap.plugin('AMap.Weather', function() {
        //创建天气查询实例
        var weather = new AMap.Weather();

        //执行实时天气信息查询
        weather.getLive('杭州市', function(err, data) {
            console.log(err, data);
            _this.weatherInfo = data
        });
    });
    // console.log(this.dayjs().startOf('date'))
  },
  methods: {
    formatPic(e) {
      let ls = e.split(',')
      return `https://public.yayale.top/${ls[0]}`
    },
    formatPicList(e) {
      let ls = e.split(',')
      let lsList = []
      for(let i in ls) {
        lsList.push(`https://public.yayale.top/${ls[i]}`)
      }
      return lsList
    },
    closeTiShi() {
      this.showTishi = false
    },
    fengxiang() {
      goods.getWxPay({id: this.$route.query.id}).then(res => {
        console.log(res)
        // this.wxPay(res.data)
        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', res.data.data,
            function(res){
                if(res.err_msg == "get_brand_wcpay_request:ok" ) {
                  Toast.success('支付成功')
                      // 使用以上方式判断前端返回,微信团队郑重提示：
                      // res.err_msg将在用户支付成功后返回
                      // ok，但并不保证它绝对可靠。
                }
            }
        );
      })        
    },
    getformat(e) {
      return dayjs(e).format('MM月DD日')
    },
    getwxSign() {
            let href = window.location.href.split('#')[0]
            let signLink = /(Android)/i.test(navigator.userAgent) ? href : window.entryUrl
            let params = {
              url: signLink,
            }
            passenger.getWxSign(params).then(res => {
              if(res.data.code == 200) {
                wx.config({
                        //beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: "wxf1e471795a1fd4e4", // 必填，企业微信的corpID
                        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
                        signature: res.data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                        jsApiList: [
                          "onMenuShareTimeline",
                          "onMenuShareAppMessage",
                          "updateAppMessageShareData", //1.4 分享到朋友
                          "updateTimelineShareData", //1.4分享到朋友圈
                          "onMenuShareQQ",
                          "onMenuShareWeibo",
                          "onMenuShareQZone",
                          "hideMenuItems",
                          "showMenuItems",
                          "chooseWXPay"
                        ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                    });
                    wx.ready(function () {
                      // wx.hideMenuItems({
                      //       menuList: [
                      //           "menuItem:share:appMessage",
                      //           "menuItem:share:timeline",
                      //           "menuItem:share:qq",
                      //           "menuItem:share:weiboApp",
                      //           "menuItem:share:appMessage",
                      //           "menuItem:copyUrl",
                      //           "menuItem:openWithQQBrowser",
                      //           "menuItem:openWithSafari",
                      //           "menuItem:share:brand",
                      //           "menuItem:share:QZone",
                      //           "menuItem:share:facebook",
                      //           "menuItem:favorite",
                      //           "menuItem:favorite",
                      //       ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
                      //   });
                        // alert("ready");
                        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                    });
                    wx.error(function (res) {
                        this.tsRemark = JSON.stringify(res);
                        // alert(JSON.stringify(res), "error");
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                    });
              }
            })
    },
    getMyPassenger() {
      let params = {}
      passenger.getMyPassenger(params).then(res => {
        if(res.data.code == 200) {
          this.myTx = res.data.data
        }
      })
    },
    formatCarNo(e) {
      const nBegin = e.substring(0,2)
      const nEnd = e.substring(e.length - 2, e.length)
      return nBegin + '***' + nEnd
    },
    goTo() {
      this.$router.push({
              name: 'goods-add',
              query: {
                isAdd: 'ADD'
              }
            })
      // if(this.myInfo.types == '2' && this.myInfo.status == '2') {
      //   Dialog.alert({
      //       title: '认证提醒',
      //       message: '您的车主审核尚未通过，请耐心等待',
      //   }).then(() => {
      //   // on close
      //       // this.getUserinfoAscy()
      //   });
      // } else {
      //   if(this.myInfoType == 1) {
      //     this.$router.push({
      //         name: 'ckfb-add',
      //         query: {
      //           isAdd: 'ADD'
      //         }
      //       })
      //   } else if(this.myInfoType == 2) {
      //     this.$router.push({
      //         name: 'czfb-add',
      //         query: {
      //           isAdd: 'ADD'
      //         }
      //       })
      //   }
      // }
    },
    goMy() {
      this.$router.push({
            name: 'my'
          })
    },
    searchXc() {
      this.getPassengerTripList();
    },
    showTrip(e) {
      if(this.myInfo.types == '2' && this.myInfo.status == '2') {
        Dialog.alert({
            title: '认证提醒',
            message: '您的车主审核尚未通过，请耐心等待',
        }).then(() => {
        // on close
            // this.getUserinfoAscy()
        });
        return
      }
      this.$router.push({
        name: "trip-info",
        query: {
          id: e.id,
        },
      });
    },
    showPassengerInfo() {
      this.$router.push({
        name: "passenger-info"
      });
    },
    onConfirm() {
      this.$refs.item.toggle();
    },
    time2(data) {
      if (data) {
        if (data == this.dayjs().format("YYYY-MM-DD")) {
          return "今天";
        } else if (
          this.dayjs(data) > this.dayjs() &&
          this.dayjs(data) < this.dayjs().add(1, "day")
        ) {
          return "明天";
        } else {
          return this.dayjs(data).format("MM月DD日");
        }
      }
    },
    getPassengerTripList() {
      this.isShowLoading = true;
      let params = {
        id: this.$route.query.id
      };
      goods.getGoodsInfo(params).then((res) => {
        this.sfcList = res.data.data;
        this.isShowLoading = false;
      });
    },
    getPassengerTripDistrictList() {
      this.isShowLoading = true;
      let params = {};
      goods.getGoodsDistrictList(params).then((res) => {
        for(let i in res.data.data) {
          for(let j in this.items) {
            if(res.data.data[i].goodsCategory == this.items[j].text) {
              this.items[j].badge = res.data.data[i].count
            }
          }
        }
        this.isShowLoading = false;
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("*************qq***Test1****Enter**************");
    console.log("to", to);
    console.log("from", from);
    console.log("next", next);
    console.log(location.pathname);
    /* eslint-disable */
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        if(to.path !== location.pathname) {
          location.assign(to.fullPath);
        } else {
          next();
        }
    } else {
      next();
    }
  },
};
</script>
<style scoped>
.goods-img{width: 100%;height: 250px;object-fit: cover;}
.goods-img1{width: 100%;object-fit: cover;}
.btm-box{
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 0 10px;
  box-sizing: border-box;
  border-top: 1px solid #f2f2f2;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
}
/deep/ .van-popup {
  background: none;
}
/deep/ .van-tree-select__nav {
  flex: 0.6;
}
/deep/ .van-info
{
  background: rgb(12, 151, 147);
}
/deep/ .van-sidebar-item--select::before {
  background-color: #42c6ff;
}
.top-box {width: 90%;margin-left: 5%;display: flex;align-items: center;justify-content: space-between;padding-top: 21px;z-index: 2;}
.top-box div p {font-size: 16px;color: #FAFAFA;line-height: 0;}
.riqi {display: flex;align-items: center;}
.riqi img {margin-right: 5px;}
.riqi span {font-size: 13px;color: #FAFAFA;}
.head-sty {width: 44px;height: 44px;border-radius: 50%;}
.dcx {
  position: absolute;
  right: 0;
  bottom: 150px;
  z-index: 999;
  background: rgb(12, 151, 147);
  color: #fff;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.wdxc {
  position: absolute;
  z-index: 9999;
  color: #fff;
}
.list-item {
  width: 100%;
  /* margin-left: 4%; */
  background: #fff;
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
  /* border-radius: 8px; */
  /* padding: 15px 15px 10px 15px; */
  margin-bottom: 15px;
  box-sizing: border-box;
}
.cklx {
  border: 1px solid #2d83e8;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #2d83e8;
}
.bar-box {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.show-more {
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #2d83e8;
  margin-bottom: 10px;
  cursor: pointer;
}
.bar-item {
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #fff;
  justify-content: center;
}
.bar-item img {
  width: 20px;
  margin-right: 5px;
}
</style>
